"use client";

import { createRef, Suspense, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { MvnxMotionEase } from "@/components/animations";
import CustomEase from "gsap/CustomEase";
import { useGSAP } from "@gsap/react";
import { useStore } from "@/app/store";
import VideoModal from "./video-modal";
import Image from "next/image";
import PauseButtonImage from "@/public/pause-button.webp";
import PlayButton from "@/public/play-button.svg";

export default function AnimatedHero() {
  const landingHeroPlayed = useStore((state) => state.landingHeroPlayed);
  const expanded = useStore((state) => state.navbarExpanded);
  const setLandingHeroPlayed = useStore((state) => state.setLandingHeroPlayed);
  const [open, setOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const tl = gsap.timeline();

  let runTimeline = true;

  console.debug("Component rendered, hero played? " + landingHeroPlayed);
  /*const videoRef = createRef<HTMLVideoElement>();

  useEffect(() => {
    if (videoRef !== null) {
      videoRef.current?.play().then(() => {});
    }
  }, [videoRef]);*/

  useGSAP(() => {
    const handleResize = () => {
      if (landingHeroPlayed) {
        runTimeline = false;
        if (window.innerWidth < 1024) {
          console.info("MOBILE");
          gsap.set("#home-vid-wrapper", {
            marginTop: 10,
            marginLeft: 10,
            height: "calc(100dvh - 30px - 80px - 70px)",
            width: "calc(100dvw - 20px)",
            borderRadius: 50,
          });
        } else {
          console.info("DESKTOP");
          gsap.set("#home-vid-wrapper", {
            marginTop: 10,
            marginLeft: 10,
            height: "calc(100dvh - 20px)",
            width: "calc(100dvw - 288px - 30px)",
            borderRadius: 50,
          });
        }
      }
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Return a cleanup function to be run on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [landingHeroPlayed]); // Empty array ensures effect is only run on mount and unmount

  /*useEffect(() => {
    const handleVideoLoaded = () => {
      tl.play();
    };

    const vid = document.getElementById("hero-vid") as HTMLVideoElement;
    vid.addEventListener("loadeddata", handleVideoLoaded);

    return () => {
      vid.removeEventListener("loadeddata", handleVideoLoaded);
    };
  }, []);*/

  useGSAP(() => {
    if (landingHeroPlayed && !expanded) {
      gsap.to("#home-vid-wrapper", {
        marginTop: 10,
        marginLeft: 10,
        height:
          window.innerWidth > 1024
            ? "calc(100dvh - 20px)"
            : "calc(100dvh - 30px - 80px - 70px)",
        width: "calc(100dvw - 20px)",
        borderRadius: 50,
        duration: 1,
        delay: 1,
        ease: MvnxMotionEase,
      });
    }
  }, [expanded, landingHeroPlayed]);

  useGSAP(() => {
    gsap.registerPlugin(TextPlugin);
    gsap.registerPlugin(CustomEase);

    //tl.pause(); // Don't immediately play the timeline

    if (landingHeroPlayed) {
      runTimeline = false;
      if (window.innerWidth < 1024) {
        gsap.set("#home-vid-wrapper", {
          marginTop: 10,
          marginLeft: 10,
          height: "calc(100dvh - 30px - 80px - 70px)",
          width: "calc(100dvw - 20px)",
          borderRadius: 50,
        });
      } else {
        gsap.set("#home-vid-wrapper", {
          marginTop: 10,
          marginLeft: 10,
          height: "calc(100dvh - 20px)",
          width: "calc(100dvw - 288px - 30px)",
          borderRadius: 50,
        });
      }
      gsap.set("[data-word-parent='headline']", { opacity: 1, scale: 1 });
      return;
    }

    if (window.innerWidth < 1024) {
      gsap.set("#home-vid-wrapper", {
        marginTop: -70,
        marginLeft: 10,
        height: "calc(100dvh - 20px)",
        width: "calc(100dvw - 20px)",
        borderRadius: 50,
      });

      gsap.to("#home-vid-wrapper", {
        marginTop: 10,
        marginLeft: 10,
        height: "calc(100dvh - 30px - 80px - 70px)",
        width: "calc(100dvw - 20px)",
        borderRadius: 50,
        delay: 1.25,
        duration: 1,
        ease: MvnxMotionEase,
      });
    }

    tl.set(
      '[data-letter-parent="go"]',
      {
        opacity: 1,
      },
      ">+=0.75",
    );

    // Start "Go"
    tl.fromTo(
      "[data-letter='go']",
      {
        opacity: 0,
        duration: 0.75,
        y: 100,
        transform: "rotate3d(1, 0, 0, -80deg)",
        ease: MvnxMotionEase,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
        transform: "rotate3d(1, 0, 0, 0deg)",
        ease: MvnxMotionEase,
        stagger: 0.1,
      },
      ">-=0.2",
    );

    tl.fromTo(
      "[data-letter='go']",
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
        transform: "rotate3d(1, 0, 0, 0deg)",
        ease: MvnxMotionEase,
      },
      {
        opacity: 0,
        y: -100,
        duration: 0.75,
        transform: "rotate3d(1, 0, 0, 80deg)",
        ease: MvnxMotionEase,
        stagger: 0.1,
      },
      ">+=0.5",
    );
    // End "Go"

    tl.set(
      '[data-letter-parent="far"]',
      {
        opacity: 1,
      },
      ">-=0.35",
    );

    // Start "Far"
    tl.fromTo(
      "[data-letter='far']",
      {
        opacity: 0,
        y: 100,
        duration: 0.85,
        transform: "rotate3d(1, 0, 0, -80deg)",
        ease: MvnxMotionEase,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.85,
        transform: "rotate3d(1, 0, 0, 0deg)",
        ease: MvnxMotionEase,
        stagger: 0.1,
      },
      ">-=0.2",
    );

    tl.fromTo(
      "[data-letter='far']",
      {
        opacity: 1,
        y: 0,
        duration: 0.85,
        transform: "rotate3d(1, 0, 0, 0deg)",
        ease: MvnxMotionEase,
      },
      {
        opacity: 0,
        y: -100,
        duration: 0.85,
        transform: "rotate3d(1, 0, 0, 80deg)",
        ease: MvnxMotionEase,
        stagger: 0.1,
      },
      ">+=0.5",
    );
    // End "Far"

    tl.set(
      '[data-letter-parent="fast"]',
      {
        opacity: 1,
      },
      ">-=0.35",
    );

    // Start "Fast"
    tl.fromTo(
      "[data-letter='fast']",
      {
        opacity: 0,
        y: 100,
        duration: 0.95,
        transform: "rotate3d(1, 0, 0, -80deg)",
        ease: MvnxMotionEase,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.95,
        transform: "rotate3d(1, 0, 0, 0deg)",
        ease: MvnxMotionEase,
        stagger: 0.1,
      },
      ">-=0.3",
    );

    tl.fromTo(
      "[data-letter='fast']",
      {
        opacity: 1,
        y: 0,
        duration: 0.95,
        transform: "rotate3d(1, 0, 0, 0deg)",
        ease: MvnxMotionEase,
      },
      {
        opacity: 0,
        y: -100,
        duration: 0.95,
        transform: "rotate3d(1, 0, 0, 80deg)",
        ease: MvnxMotionEase,
        stagger: 0.1,
      },
      ">",
    );
    // End "Fast"

    tl.set(
      '[data-word-parent="headline"]',
      {
        opacity: 1,
      },
      ">-=0.35",
    );

    // Start headline
    tl.fromTo(
      "[data-word='headline']",
      {
        opacity: 0,
        y: 100,
        duration: 0.75,
        transform: "rotate3d(1, 0, 0, -80deg)",
        ease: MvnxMotionEase,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
        transform: "rotate3d(1, 0, 0, 0deg)",
        ease: MvnxMotionEase,
        stagger: 0.1,
      },
      ">-=0.3",
    );

    tl.fromTo(
      "[data-word-parent='headline']",
      { scale: 1.85, duration: 3, ease: MvnxMotionEase },
      { scale: 1, duration: 3, ease: MvnxMotionEase },
      "<",
    );
    // End headline

    tl.to(
      "#home-vid-wrapper",
      {
        marginTop: 10,
        marginLeft: 10,
        height:
          window.innerWidth > 1024
            ? "calc(100dvh - 20px)"
            : "calc(100dvh - 30px - 80px - 70px)",
        width: "calc(100dvw - 20px)",
        borderRadius: 50,
        duration: 3,
        ease: MvnxMotionEase,
      },
      "<",
    );
    tl.to(
      "#home-vid-wrapper",
      {
        width:
          window.innerWidth > 1024
            ? "calc(100dvw - 288px - 30px)"
            : "calc(100dvw - 20px)",
        duration: 1,
        ease: MvnxMotionEase,
      },
      ">+=1",
    );

    tl.eventCallback("onComplete", () => {
      console.info("Landing hero completed sequence");
      setLandingHeroPlayed(true);
    });
  });

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
        id="home-vid-wrapper"
        className="absolute overflow-hidden w-full flex bg-gradient-mvnx h-full z-50"
      >
        <section className="absolute w-full h-full">
          <p
            style={{ opacity: 0 }}
            data-letter-parent="go"
            className="scale-[200%] w-full absolute -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2 text-center text-h1-mobile lg:text-h1-desktop font-medium text-white"
          >
            <span className="inline-block" data-letter="go">
              G
            </span>
            <span className="inline-block" data-letter="go">
              o
            </span>
          </p>
          <p
            style={{ opacity: 0 }}
            data-letter-parent="far"
            className="scale-[200%] w-full absolute -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2 text-center text-h1-mobile lg:text-h1-desktop font-medium text-white"
          >
            <span className="inline-block" data-letter="far">
              F
            </span>
            <span className="inline-block" data-letter="far">
              a
            </span>
            <span className="inline-block" data-letter="far">
              r
            </span>
          </p>
          <p
            style={{ opacity: 0 }}
            data-letter-parent="fast"
            className="scale-[200%] w-full absolute -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2 text-center text-h1-mobile lg:text-h1-desktop font-medium text-white"
          >
            <span className="inline-block" data-letter="fast">
              F
            </span>
            <span className="inline-block" data-letter="fast">
              a
            </span>
            <span className="inline-block" data-letter="fast">
              s
            </span>
            <span className="inline-block" data-letter="fast">
              t
            </span>
          </p>
          <p
            style={{ opacity: 0 }}
            data-word-parent="headline"
            className="scale-[150%] w-full absolute -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2 text-center text-h1-mobile lg:text-h1-desktop font-medium text-white"
          >
            <span className="inline-block" data-word="headline">
              Connect
            </span>{" "}
            <span className="inline-block" data-word="headline">
              to
            </span>{" "}
            <span className="inline-block" data-word="headline">
              what&apos;s
            </span>{" "}
            <span className="inline-block" data-word="headline">
              next
            </span>
          </p>
        </section>
        {runTimeline && (
          /*<Suspense>
            <video
              //ref={videoRef}
              id="hero-vid"
              autoPlay={true}
              muted={true}
              preload="auto"
              playsInline={true}
              controls={false}
              className="h-full w-full object-cover"
            >
              <source src="/landing.webm" type="video/webm" />
              <div className="h-full w-full object-cover bg-gradient-mvnx" />
            </video>
          </Suspense>*/
          <div className="h-full w-full object-cover bg-gradient-mvnx" />
        )}
        {!runTimeline && (
          <div className="h-full w-full object-cover bg-gradient-mvnx" />
        )}
        {/*<button
          onClick={() => {
            setIsPlaying(!isPlaying);
            const vid = document.getElementById("hero-vid") as HTMLVideoElement;
            isPlaying ? vid.pause() : vid.play();
          }}
          className="fixed bottom-[120px] lg:bottom-[50px] left-[50px] z-[500]"
        >
          <Image
            src={isPlaying ? PauseButtonImage : PlayButton}
            alt={isPlaying ? "Pause button" : "Play button"}
          />
        </button>*/}
      </div>
    </>
  );
}
